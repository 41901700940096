export const CATEGORY = 'MY CATEGORY'
export const FOR_HIM = 'FOR HIM'
export const IMPACT = 'IMPACT'
export const SHOP = 'SHOP'
export const LOOK_BOOK = 'LOOKBOOK'
export const PRESS = 'PRESS'
export const CELEBRITY = 'CELEBRITY'
export const LAB_GROWN = 'LAB GROWN DIAMONDS'
export const ABOUT_US = 'ABOUT US'
export const ACCOUNT = 'ACCOUNT'
export const LOGIN = 'LOG IN'
export const BLOG = 'BLOG'
export const ETERNITY_BANDS = 'Eternity Bands'
export const FINE_JEWELLERY_GIFTS = 'FINE JEWELLERY GIFTS'
export const CUSTERM_SERVICES = '24/7 CUSTOMER SERVICE'
export const LIFETIME_WARRANTY = 'LIFETIME WARRANTY'
export const FREE_INTERNATIONAL_SHIPPING = 'FREE INTERNATIONAL SHIPPING*'
export const MONEY_BACK_GUARANTEE = '100% MONEY BACK GUARANTEE'
export const YOUR_ACCOUNT = 'your account'
export const LOGOUT = 'log out'
export const LOGOUT_MESSAGE = 'You have been successfully logged out.'
export const CONTACT_FORM = 'CONTACT FORM'
export const PLEASE_CALL = 'Please call us at 18002120299'
export const CUSTOMIZE_PRODUCT = 'Customize the product'
export const SELECT_SIZE = 'SELECT YOUR SIZE'
export const PRODUCT_DETAIL = 'PRODUCT DETAILS'
export const SIGNUP_NEWSLETTER = 'Signup For Newsletters'
export const SHOP_BY = 'SHOP BY'
export const SHIPPING_ADDRESS = 'SHIPPING ADDRESS'
export const ADD_NOTE_TO_ORDER = 'ADD A NOTE TO YOUR ORDER'
export const ORDER_REVIEW = 'ORDER REVIEW'
export const TESTIMONIALS = 'Testimonials'
export const APPOINMENT_TITLE = 'Consult with a Diamond Expert'
export const APPOINMENT_DES = 'Buying Engagement Ring & Fine Jewellery Made Easy, Consult a Diamond Expert & Stylist From the Comfort of Your Home.'


export const BTN_SUBMIT = 'SUBMIT'
export const BTN_BUYNOW = 'Buy Now'
export const BTN_KWONMORE = 'Know More'
export const BTN_JOIN = 'join'
export const BTN_CLEAREALL = 'CLEAR ALL'
export const BTN_APPLY = 'APPLY'
export const BTN_REMOVE = 'Romove'
export const BTN_CHECKOUT_SECURELY = 'Checkout Securely'
export const BTN_PLACEORDER = 'PLACE ORDER'
export const BTN_APPOINMENT ='Book a Virtual Appointment'
export const BTN_CONFIRM ='CONFIRM'

