import React,{ useEffect, useState } from "react";
import ImpCards from "../impact-cards/ImpCards";
import SustainAbility from "../../sustainAbility/SustainAbility";
import jointlogo from '../../../assets/Impact/campaign/jointlogo.avif'
import united from '../../../assets/Impact/campaign/united.jpg'
import './campaign.css'
import { storImagePath } from "../../../../Utils/globalFunctions/GlobalFunction";

const Campaign = () => {
  return (
    <div className="campaignMainDiv" style={{ backgroundColor: "#fff", width: "100%", height: 'auto' }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          color: "#7d7f85",
          marginTop: '80px'
        }}
      >
        <p
          style={{
            fontSize: "30px",
            letterSpacing: "1px",
            color: '#3f3151'
          }}
        >
         Honoring the Pledge
        </p>
        <p
          style={{
            width: "80%",
            textAlign: "center",
            fontSize: "25px",
            lineHeight: "28px",
            color: '#5f497a'
          }}
          className="campaignDesc"
        >
         When we band together, we can achieve the Sustainable Development Goals with unstoppable power. with only six years remaining, it is imperative that we fulfill our 2030 commitment. When we work together, we can accomplish the extraordinary, fulfill our commitment to the Sustainable Development Goals, and build the world we want to live in now and in the future.
        </p>
      </div>

      <ImpCards />
      {/* <div className="comapinSustain" style={{ padding: "0px 85px 0px", marginTop: '55px' }}  >
        <SustainAbility />
      </div>
      <div className="campaingImages" style={{ padding: "0px 85px 0px", marginTop: '45px' }}>
        <img src={`${storImagePath()}/images/HomePage/impact/ImpactMainBanner2.jpg`} alt={''} style={{width:'100%'}} />
      </div>
      <div style={{ padding: "0px 85px 0px", marginTop: '45px' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={jointlogo} alt={''} />
        </div>
        <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingBottom: '30px' }}>
          <div style={{ width: '568px', marginTop: '25px', color: '#7d7f85' }}>
            <p style={{ fontFamily: 'FreightDisp Pro Medium', fontSize: '26px' }}>Keeping The Promise</p>
            <p style={{ fontFamily: 'TT Commons, sans-serif', fontSize: '13px' }}>
              Together, we will create an unstoppable force in achieving the&nbsp;
              <b>Sustainable Development Goals</b>. With just nine years left the
              time has come to deliver on our 2030 promise.
            </p>
            <p style={{ fontFamily: 'TT Commons, sans-serif', fontSize: '13px' }}>
              Together, we can deliver the extraordinary and we must keep
              our promise for the Global Goals and create the world we
              want to see today and for our future.
            </p>
            <p style={{ fontFamily: 'TT Commons, sans-serif', fontSize: '13px' }}> <u style={{ cursor: 'pointer' }}>Donate Now</u>&nbsp;Directly to UNSDG fund!</p>
          </div>
        </div>
      </div> */}

    </div>
  );
};

export default Campaign;
